import * as auth from 'external/domains/auth/passwordless/client';
import { RTCPleditor } from '../RTCPleditor';
import { useGetExternalUser } from '../hooks';
import { EXTERNAL_COMPOSED_PLUGINS } from '../plugins';
export function ExternalComposedRTCPleditor() {
    const { currentUser } = useGetExternalUser();
    const isSessionExpired = auth.expiredSession();
    const toolbarItems = ['<->', 'blockComment', '|', 'toggleComments'];
    const balloonToolbarItems = ['blockCommentCompact'];
    return (<RTCPleditor documentType="composed" toolbarItems={toolbarItems} balloonToolbarItems={balloonToolbarItems} currentUser={currentUser} plugins={EXTERNAL_COMPOSED_PLUGINS} isSessionExpired={isSessionExpired}/>);
}
