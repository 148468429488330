import { TrackChanges } from 'pleditor';
import * as auth from 'external/domains/auth/passwordless/client';
import { BaseFreeTextEditor } from './BaseFreeTextEditor';
import { PleditorError } from './components/PleditorError';
import { PleditorLoader } from './components/PleditorLoader';
import { useGetDocumentData } from './hooks/useGetDocumentData';
import { ExternalApiConnector } from './plugins/ExternalApiConnector';
import { ExternalSuggestions } from './plugins/ExternalSuggestions';
import { FlexGap } from './plugins/FlexGap';
import { InlineAssistantShortcut } from './plugins/InlineAssistantShortcut';
import { InlineComment } from './plugins/InlineComment';
import { SaveHandler } from './plugins/SaveHandler';
import { SuggestionsAdapter } from './plugins/SuggestionsAdapter';
import { ToggleComments } from './plugins/ToggleComments';
import { useActiveDocument } from '../../../hooks/usePreview';
export function ExternalFreeTextPleditor() {
    const { user } = auth.state.get();
    const { id, company } = useActiveDocument();
    const { data, loading, error } = useGetDocumentData(id, company === null || company === void 0 ? void 0 : company.id);
    const currentUser = {
        id: user === null || user === void 0 ? void 0 : user.sub,
        name: user === null || user === void 0 ? void 0 : user.name,
    };
    if (loading) {
        return <PleditorLoader />;
    }
    if (error) {
        return <PleditorError />;
    }
    return (<BaseFreeTextEditor documentId={id} currentUser={currentUser} data={data.body || ''} versionNumber={data.versionNumber} featureAvailability={data.featureAvailability} extraPlugins={[
            FlexGap,
            ExternalApiConnector,
            InlineComment,
            ToggleComments,
            SaveHandler,
            TrackChanges,
            SuggestionsAdapter,
            ExternalSuggestions,
            InlineAssistantShortcut,
        ]} toolbar={{
            items: ['<->', 'inlineComment', '|', 'toggleComments'],
        }} balloonToolbar={{ items: ['inlineCommentCompact'] }}/>);
}
