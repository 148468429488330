import * as auth from 'external/domains/auth/passwordless/client';
import { PleditorError } from '../../components/PleditorError';
import { PleditorLoader } from '../../components/PleditorLoader';
import { RTCPleditor } from '../RTCPleditor';
import { useGetDocumentFeatures, useGetExternalUser } from '../hooks';
import { EXTERNAL_FREE_TEXT_PLUGINS } from '../plugins';
export function ExternalFreeTextRTCPleditor() {
    const { currentUser } = useGetExternalUser();
    const isSessionExpired = auth.expiredSession();
    const { documentFeatures, loading: loadingFeatures, error: featuresError, } = useGetDocumentFeatures();
    const loading = loadingFeatures;
    const error = featuresError;
    if (loading) {
        return <PleditorLoader />;
    }
    if (error) {
        return <PleditorError />;
    }
    const toolbarItems = ['<->', 'inlineComment', '|', 'toggleComments'];
    const balloonToolbarItems = ['inlineCommentCompact'];
    return (<RTCPleditor documentType="freetext" toolbarItems={toolbarItems} balloonToolbarItems={balloonToolbarItems} currentUser={currentUser} featureAvailability={documentFeatures} plugins={EXTERNAL_FREE_TEXT_PLUGINS} isSessionExpired={isSessionExpired}/>);
}
