import { RestrictedEditingMode } from 'pleditor';
import * as auth from 'external/domains/auth/passwordless/client';
import { useGetDocumentData } from './useGetDocumentData';
import { BaseComposedEditor } from '../BaseComposedEditor';
import { PleditorError } from '../components/PleditorError';
import { PleditorLoader } from '../components/PleditorLoader';
import { BlockComment } from '../plugins/BlockComment';
import { ExternalApiConnector } from '../plugins/ExternalApiConnector';
import { FlexGap } from '../plugins/FlexGap';
import { SaveHandler } from '../plugins/SaveHandler';
import { ToggleComments } from '../plugins/ToggleComments';
export function ExternalComposedPleditor() {
    const { user } = auth.state.get();
    const currentUser = {
        id: user === null || user === void 0 ? void 0 : user.sub,
        name: user === null || user === void 0 ? void 0 : user.name,
    };
    const { data, loading, error } = useGetDocumentData();
    if (loading) {
        return <PleditorLoader />;
    }
    if (error || !data.body) {
        return <PleditorError />;
    }
    return (<BaseComposedEditor documentId={data.documentId} currentUser={currentUser} extraPlugins={[
            FlexGap,
            ExternalApiConnector,
            BlockComment,
            ToggleComments,
            RestrictedEditingMode,
            SaveHandler,
        ]} toolbar={{
            items: ['<->', 'blockComment', '|', 'toggleComments'],
        }} balloonToolbar={{ items: ['blockCommentCompact'] }} data={data.body} versionNumber={data.versionNumber}/>);
}
